.standard-select {
    width: 75px !important;
}


.react-datepicker__input-container > input {
    width: 75px !important;
}


.ck-in-denunce {
    margin-left: -0.7vw;
}


.page-content {
    overflow: scroll !important;
}

.margin-btn-attivity {
    margin-left: -4.5vw;
    cursor: pointer;
}